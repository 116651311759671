import useRemoteList from '@/views/hooks/useRemoteList'
import { ref, watch, computed } from '@vue/composition-api'
import ability from '@/libs/acl/ability'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import i18n from '@/libs/i18n'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'
import FileManager from '@/utils/FileManager'

import { StorageManager, VEHICULES_LIST_FILTERS_KEY } from '@/utils/StorageManager'

export default function useClientsList(props) {
  const selectedClientId = ref(null)

  // Filters
  const vehiculeStepFilter = props.vehiculeStep
  const siteProvenanceIdFilter = ref(null)
  const siteProvenanceOptions = ref([])
  const modeleIdFilter = ref(null)
  const modeleOptions = ref([])

  const numeroOrdreFilter = ref(null)
  const numeroSerieFilter = ref(null)
  const dateAraFilter = ref(null)

  const enableDateFilter = ref(false)

  const today = moment()
  const startDateFilter = ref(today.format('YYYY-MM-DD'))

  const tomorrow = today.clone().add(1, 'days')
  const endDateFilter = ref(tomorrow.format('YYYY-MM-DD'))
  const emplacementFilter = ref(null)

  // Use toast
  const toast = useToast()
  // use List
  const list = useRemoteList()

  const pageIndex = computed(() => list.currentPage.value - 1)

  const filtersTitle = computed(() => {
    const filters = [siteProvenanceIdFilter?.value, modeleIdFilter?.value, numeroOrdreFilter?.value, numeroSerieFilter?.value, dateAraFilter?.value, enableDateFilter?.value, emplacementFilter?.value]
    const activeFilters = filters.filter(v => v).length
    if (activeFilters === 0) {
      return 'Aucun filtre actif'
    }
    return activeFilters > 1 ? `${activeFilters} filtres actifs` : '1 filtre actif'
  })

  const storeVehiculesFilters = () => {
    const storedFilters = {
      startDateFilter: startDateFilter.value,
      endDateFilter: endDateFilter.value,
      numeroOrdreFilter: numeroOrdreFilter.value,
      numeroSerieFilter: numeroSerieFilter.value,
      dateAraFilter: dateAraFilter.value,
      modeleOptions: modeleOptions.value?.filter(v => v.id === modeleIdFilter.value),
      modeleIdFilter: modeleIdFilter.value,
      enableDateFilter: enableDateFilter.value,
      siteProvenanceOptions: siteProvenanceOptions.value?.filter(v => v.id === siteProvenanceIdFilter.value),
      siteProvenanceIdFilter: siteProvenanceIdFilter.value,
      emplacementFilter: emplacementFilter.value,
    }

    StorageManager.setFilter(VEHICULES_LIST_FILTERS_KEY, storedFilters)
  }

  const fetchData = () => {
    let dateStart
    let dateEnd

    if (enableDateFilter.value) {
      dateStart = startDateFilter.value
      dateEnd = endDateFilter.value
    }

    const filters = {
      DateStart: dateStart,
      DateEnd: dateEnd,
      VehiculeStep: vehiculeStepFilter,
      'FiltersToApply.SiteProvenanceId': siteProvenanceIdFilter.value,
      'FiltersToApply.NumeroOrdre': numeroOrdreFilter.value,
      'FiltersToApply.NumeroSerie': numeroSerieFilter.value,
      'FiltersToApply.DateARA': dateAraFilter.value,
      'FiltersToApply.ModeleId': modeleIdFilter.value,
      'FiltersToApply.Emplacement': emplacementFilter.value,
    }

    let actionName = 'vehicule/fetchVehicules'
    if (ability.can('read', 'Client')) {
      actionName = 'vehicule/fetchVehiculesByClient'
      if (selectedClientId.value == null) {
        return []
      }
    }

    let sortColumn = list.sortColumn.value ? list.sortColumn.value : 'SiteProvenanceId'
    if (sortColumn === 'SiteDestinationNom') {
      sortColumn = 'SiteDestinationId'
    }

    return store.dispatch(actionName, {
      clientId: selectedClientId.value,
      queryParams: {
        SortColumn: sortColumn,
        SortDirection: list.sortDirection.value,
        PageIndex: pageIndex.value,
        PageSize: list.perPage.value,
        ...filters,
      },
    })
      .then(response => {
        storeVehiculesFilters()
        const { data, headers } = response
        list.total.value = parseInt(headers['x-total-count'], 10)
        siteProvenanceOptions.value = data.sitesProvenance
        modeleOptions.value = data.modeles

        if (siteProvenanceOptions.value.filter(p => p.id === siteProvenanceIdFilter.value).length === 0) {
          siteProvenanceIdFilter.value = null
        }
        if (modeleOptions.value.filter(m => m.id === modeleIdFilter.value).length === 0) {
          modeleIdFilter.value = null
        }

        return data.vehicules
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('client.list.error.fetch'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return []
      })
  }

  const deleteItem = itemId => {
    if (!window.confirm('Etes vous certain de vouloir supprimer définitivement ce véhicule ?')) {
      return
    }
    store
      .dispatch('vehicule/deleteVehicule', itemId)
      .then(() => {
        list.refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('client.list.error.delete'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const exportToExcel = () => {
    let actionName = 'vehicule/exportVehicules'
    if (ability.can('read', 'Client')) {
      actionName = 'vehicule/exportVehiculesByClient'
    }

    let dateStart
    let dateEnd

    if (enableDateFilter.value) {
      dateStart = startDateFilter.value
      dateEnd = endDateFilter.value
    }

    store.dispatch(actionName, {
      clientId: selectedClientId.value,
      queryParams: {
        DateStart: dateStart,
        DateEnd: dateEnd,
        VehiculeStep: vehiculeStepFilter,
        'FiltersToApply.SiteProvenanceId': siteProvenanceIdFilter.value,
        'FiltersToApply.NumeroOrdre': numeroOrdreFilter.value,
        'FiltersToApply.NumeroSerie': numeroSerieFilter.value,
        'FiltersToApply.DateARA': dateAraFilter.value,
        'FiltersToApply.ModeleId': modeleIdFilter.value,
        'FiltersToApply.Emplacement': emplacementFilter.value,
        SortColumn: list.sortColumn.value,
        SortDirection: list.sortDirection.value,
      },
    }).then(response => {
      if (response && response.data) { FileManager.forceFileDownload(response.data.filename, response.data.contentType, response.data.body) }
    })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Impossible d\'exporter les données vers Excel',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const clearAllFilters = () => {
    siteProvenanceIdFilter.value = null
    numeroOrdreFilter.value = null
    numeroSerieFilter.value = null
    dateAraFilter.value = null
    modeleIdFilter.value = null
    enableDateFilter.value = null
    startDateFilter.value = today.format('YYYY-MM-DD')
    endDateFilter.value = tomorrow.format('YYYY-MM-DD')
    emplacementFilter.value = null
    list.refetchData()
  }

  const downloadEtiquette = () => {
    store.dispatch('vehicule/fetchEtiquette').then(response => {
      if (response && response.data) { FileManager.forceFileDownload(response.data.filename, response.data.contentType, response.data.body) }
    })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Impossible d\'imprimer l\'étiquette',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  watch([siteProvenanceIdFilter, modeleIdFilter, numeroOrdreFilter, numeroSerieFilter, dateAraFilter, enableDateFilter, startDateFilter, endDateFilter, emplacementFilter], () => {
    list.refetchData()
  })

  watch([selectedClientId], () => {
    if (selectedClientId.value !== null || !ability.can('read', 'Client')) {
      list.refetchData()
    }
  })

  // DATA
  const tableColumns = [
    { label: 'Modèle', key: 'modeleNom', sortable: true },
    {
      label: 'Provenance', key: 'siteProvenanceNom', sortable: true,
    },
    { label: 'Numéro d\'ordre', key: 'numeroOrdre', sortable: true },
    {
      label: 'Numéro de série', key: 'numeroSerie', sortable: true, stickyColumn: true,
    },
    {
      label: 'Date d\'ARA', key: 'dateARA', sortable: true, formatter: date => (date !== null ? moment(date).format('DD/MM/YYYY') : ''),
    },
    {
      label: 'Date de sortie', key: 'dateLivraison', sortable: true, formatter: date => (date !== null ? moment(date).format('DD/MM/YYYY') : ''),
    },
    {
      label: 'Destination', key: 'siteDestinationNom', sortable: true,
    },
    {
      label: 'Emplacement', key: 'emplacement', sortable: true,
    },
    {
      label: 'Stock', key: 'nbJourStock', sortable: false,
    },
  ]

  if (ability.can('update', 'Vehicule') || ability.can('delete', 'Vehicule')) {
    tableColumns.push({ label: 'Actions', key: 'actions' })
  }

  return {
    tableColumns,
    fetchData,
    deleteItem,

    // list
    ...list,

    selectedClientId,

    // filter
    filtersTitle,
    vehiculeStepFilter,
    siteProvenanceIdFilter,
    siteProvenanceOptions,
    modeleIdFilter,
    modeleOptions,
    numeroOrdreFilter,
    numeroSerieFilter,
    dateAraFilter,
    enableDateFilter,
    startDateFilter,
    endDateFilter,
    emplacementFilter,

    exportToExcel,
    downloadEtiquette,
    clearAllFilters,
  }
}
