<template>

  <div>

    <!-- Client Selector -->
    <client-selector
      v-if="$ability.can('read', 'Client')"
      :selected-client-id.sync="selectedClientId"
    />

    <!-- Filters -->
    <div class="filters">
      <app-collapse>
        <app-collapse-item :title="filtersTitle">
          <vehicules-list-filters
            :site-provenance-id-filter.sync="siteProvenanceIdFilter"
            :site-provenance-options="siteProvenanceOptions"
            :modele-id-filter.sync="modeleIdFilter"
            :modele-options="modeleOptions"
            :numero-ordre-filter.sync="numeroOrdreFilter"
            :numero-serie-filter.sync="numeroSerieFilter"
            :date-ara-filter.sync="dateAraFilter"
            :enable-date-filter.sync="enableDateFilter"
            :start-date-filter.sync="startDateFilter"
            :end-date-filter.sync="endDateFilter"
            :selected-client-id.sync="selectedClientId"
            :emplacement-filter.sync="emplacementFilter"
          />
          <b-row class="justify-content-end">
            <div class="col-2 clearBtnRow">
              <b-button
                class="pull-right"
                variant="primary"
                @click="onClearFiltersClicked()"
              >
                <span class="text-nowrap">{{ "Effacer tous les filtres" }}</span>
              </b-button>
            </div>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 select-cursor"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="$ability.can('create', 'Vehicule')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="onAddVehiculeButtonClicked()"
              >
                <span class="text-nowrap">{{ "Ajouter un véhicule" }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun véhicule ne correspond à la recherche"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$ability.can('update', 'Vehicule')"
              :to="{ name: 'update-vehicule', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('action.edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$ability.can('delete', 'Vehicule')"
              @click="deleteConfirm(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('action.delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }}-{{ dataMeta.to }} {{ $t('on') }} {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
        <div class="mt-2">
          <b-button
            v-if="total"
            class="mr-2"
            variant="outline-primary"
            @click="exportToExcel()"
          >
            Exporter vers Excel
          </b-button>
          <b-button
            v-if="$ability.can('Read', 'Client')"
            variant="outline-primary"
            @click="downloadEtiquette()"
          >
            Imprimer une étiquette vide
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import router from '@/router'
import vSelect from 'vue-select'
import { watch } from '@vue/composition-api'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import useVehiculesList from './useVehiculesList'
import VehiculesListFilters from './VehiculesListFilters.vue'
import ClientSelector from '../../site/sites-list/ClientSelector.vue'
import { StorageManager, FILTER_CLIENT_KEY, VEHICULES_LIST_FILTERS_KEY } from '../../../utils/StorageManager'

export default {

  components: {

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,

    ClientSelector,
    VehiculesListFilters,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    vehiculeStep: {
      type: String,
      required: true,
    },
  },

  mounted() {
    if (this.$ability.can('read', 'Client')) {
      if (router.currentRoute.params.id !== null && router.currentRoute.params.id !== undefined) {
        this.selectedClientId = router.currentRoute.params.id
      } else if (StorageManager.getFilter(FILTER_CLIENT_KEY) != null) {
        this.selectedClientId = StorageManager.getFilter(FILTER_CLIENT_KEY)
      }
    }
    if (StorageManager.hasFilter(VEHICULES_LIST_FILTERS_KEY)) {
      const vehiculesItems = StorageManager.getFilter(VEHICULES_LIST_FILTERS_KEY)
      const vehiculeKeys = Object.keys(vehiculesItems)

      vehiculeKeys.forEach(key => {
        this[key] = vehiculesItems[key]
      })
    }
  },

  setup(props) {
    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,
      siteProvenanceIdFilter,
      modeleIdFilter,
      numeroOrdreFilter,
      numeroSerieFilter,
      dateAraFilter,
      enableDateFilter,
      startDateFilter,
      endDateFilter,
      emplacementFilter,
      selectedClientId,
      siteProvenanceOptions,
      modeleOptions,
      filtersTitle,
      exportToExcel,
      downloadEtiquette,
      clearAllFilters,

    } = useVehiculesList(props)

    const onAddVehiculeButtonClicked = () => {
      router.push({ name: 'add-vehicule', params: { clientId: selectedClientId.value } })
    }

    const onClearFiltersClicked = () => {
      clearAllFilters()
    }

    watch(selectedClientId, val => {
      StorageManager.setFilter(FILTER_CLIENT_KEY, val)
    })

    return {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,

      // client selector
      selectedClientId,
      filtersTitle,
      siteProvenanceIdFilter,
      siteProvenanceOptions,
      modeleIdFilter,
      modeleOptions,
      numeroOrdreFilter,
      numeroSerieFilter,
      dateAraFilter,
      enableDateFilter,
      startDateFilter,
      endDateFilter,
      emplacementFilter,

      exportToExcel,
      downloadEtiquette,

      // event
      onAddVehiculeButtonClicked,
      onClearFiltersClicked,
    }
  },

  methods: {
    deleteConfirm(item) {
      this.$bvModal.msgBoxConfirm(`Confirmer vous la suppression du véhicule ${item.numeroSerie} ?`, {
        title: 'Suppression véhicule',
        size: 'sm',
        okTitle: 'Oui',
        cancelTitle: 'Non',
      }).then(confirm => {
        if (confirm) { this.deleteItem(item.id) }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.filters {
  margin-bottom:2rem;
}

.clearBtnRow {
  padding-top:2rem;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
