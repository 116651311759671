<template>
  <vehicules-list vehicule-step="stock" />

</template>

<script>

import VehiculesList from './VehiculesList.vue'

export default {
  components: {
    VehiculesList,
  },
}
</script>

<style lang="scss" scoped>

</style>
