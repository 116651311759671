<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filtrer
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="8"
          class="mb-md-0 mb-2"
        >
          <b-form-checkbox
            :checked="enableDateFilter"
            @input="(val) => $emit('update:enableDateFilter', val)"
          >
            Restreindre à un créneau horaire
          </b-form-checkbox>
          <div class="date-filter">
            <span class="text-nowrap mr-1">Entre le</span>
            <b-form-datepicker
              start-weekday="1"
              :value="startDateFilter"
              class="w-100"
              reset-button
              today-button
              v-bind="labels || {}"
              :max="endDateFilter"
              placeholder="Saisir une date"
              @input="(val) => $emit('update:startDateFilter', val)"
            />
            <span class="text-nowrap mx-1">et le</span>
            <b-form-datepicker
              start-weekday="1"
              :value="endDateFilter"
              class="w-100"
              reset-button
              today-button
              v-bind="labels || {}"
              :min="startDateFilter"
              placeholder="Saisir une date"
              @input="(val) => $emit('update:endDateFilter', val)"
            />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Provenance</label>
          <v-select
            :value="siteProvenanceIdFilter"
            :options="siteProvenanceOptions"
            :reduce="siteProvenance => siteProvenance.id"
            label="nom"
            class="w-100 select-cursor"
            @input="(val) => $emit('update:siteProvenanceIdFilter', val)"
          ><div slot="no-options">
            Aucune option
          </div></v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Modèle</label>
          <v-select
            :value="modeleIdFilter"
            :options="modeleOptions"
            :reduce="modele => modele.id"
            label="nom"
            class="w-100 select-cursor"
            @input="(val) => $emit('update:modeleIdFilter', val)"
          ><div slot="no-options">
            Aucune option
          </div></v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Numéro d'ordre</label>
          <b-form-input
            :value="numeroOrdreFilter"
            class="w-100"
            @input="(val) => $emit('update:numeroOrdreFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Numéro de série</label>
          <b-form-input
            :value="numeroSerieFilter"
            class="w-100"
            @input="(val) => $emit('update:numeroSerieFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date d'ARA</label>
          <b-form-datepicker
            start-weekday="1"
            :value="dateAraFilter"
            class="w-100"
            reset-button
            today-button
            v-bind="labels || {}"
            placeholder="Saisir une date"
            @input="(val) => $emit('update:dateAraFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Emplacement</label>
          <b-form-input
            :value="emplacementFilter"
            class="w-100"
            @input="(val) => $emit('update:emplacementFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BFormDatepicker, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import datePickerLabels from '@/utils/datePickerLabels'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
  },
  props: {
    enableDateFilter: {
      type: [Boolean],
      default: false,
    },
    startDateFilter: {
      type: [String, null],
      default: null,
    },
    endDateFilter: {
      type: [String, null],
      default: null,
    },
    siteProvenanceIdFilter: {
      type: [Number, null],
      default: null,
    },
    siteProvenanceOptions: {
      type: [Array],
      default: () => [],
    },
    modeleIdFilter: {
      type: [Number, null],
      default: null,
    },
    modeleOptions: {
      type: [Array],
      default: () => [],
    },
    numeroOrdreFilter: {
      type: [String, null],
      default: null,
    },
    numeroSerieFilter: {
      type: [String, null],
      default: null,
    },
    dateAraFilter: {
      type: [String, null],
      default: null,
    },
    selectedClientId: {
      type: [Number, null],
      default: null,
    },
    emplacementFilter: {
      type: [String],
      default: null,
    },
  },
  data() {
    return {
      labels: datePickerLabels,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.date-filter {
  display: flex;
  span {
    align-self: center;
  }

}

.dropdown-menu {
  z-index:50 !important;
}

@media screen and (max-width: 1024px) {
  .date-filter {
    flex-direction: column;
    span {
      align-self: start;
    }
  }
}
</style>
